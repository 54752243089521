.blocks {
    padding: 50px 10px 0;

    &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.details_block {
    padding: 10px;
    border: 1px solid $blue;
    border-radius: 5px;
    width: 300px;
    margin-right: 10px;
    margin-bottom: 10px;

    &--sm {
        width: 200px;
    }

    &--commands {
        .commands {
            grid-template-columns: repeat(2, 1fr);
            height: auto;
            display: grid
        };
    }

    &--qr {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
        width: 100%;
        height: 90vh;
        border: none;

        h2 {
            font-weight: bold;
            margin-bottom: 20px;
        }

        img {
            margin-bottom: 20px;
            width: 200px;
        }

        p {
            text-align: center;
        }
    }
}

.myChart {
    height: 70% !important;
    
}

@include media-breakpoint-down(sm) {
    .details_block {
        width: calc(50% - 10px);
        margin: 0 5px 10px;
        min-height: 270px;

        &--wide {
            width: calc(100% - 10px);
            margin: 0 5px 10px;
        }

        &--qr {
            width: calc(100% - 10px);
            margin: 0 5px 10px;
            height: 90vh;
        }
    }
}