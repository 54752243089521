.page {
    padding: 0 15px;
    display: flex;
    flex-direction: row;

    &__left {
        // padding-right: 7px;
        // width: 75%;
        width: 100%;
    }

    &__right {
        padding-left: 7px;
        width: 25%;
    }
}

.accordion {
    &__panel {
        padding: 15px 15px 0;
    }
}

.details {
    &__container {
        background-color: white;
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 5px;
        color: #2b3e50;

        &--fixed {
            padding: 5px;
            position: fixed;
            width: 780px;
            background: #fff;
            z-index: 10;
        }

        .commands {
            grid-template-columns: repeat(2, 1fr);
            height: auto;
        }
    }

    &__header {
        text-align: center;
        color: #2b3e50;
        font-size: 14px;
        font-weight: bold;

        &--big {
            font-size: 20px;
        }
    }
}

.listWrapper__cardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, auto));
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.listWrapper__cardList {
    display: flex;
    flex-direction: column;
}

.gridChanger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;

    button {
        margin-left: 15px;
        color: #2b3e50;
        padding: 3px;
        border: 1px solid #2b3e50;
        border-radius: 3px;
        display: flex;

        svg {
            pointer-events: none;
        }

        &.active {
            color: #fff;
            background: #2b3e50;
        }
    }
}

.cardGrid {
    background-color: #fff;
    border: 1px solid #d9d5d5;
    border-radius: 9px;
    cursor: pointer;

    &__head {
        text-align: left;
        color: #4a76a8;
        line-height: 26px;
        padding: 7px;
        border-bottom: 1px solid #d9d5d5;

        &__number {
            font-size: 25px;
            font-weight: bold;
            display: inline-block;
            width: 40px;
            text-align: center;
        }

        &__address {
            font-size: 25px;
        }
    }

    &__content {
        height: 60px;
        /*display: grid;
        grid-template-columns: 1fr 3fr 2fr;*/

        &__first {
            /*background-image: url('../../components/market/icons/events_count.svg');*/
            background-position-y: 5px;
            background-position-x: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border-right: 1px solid #d9d5d5;

            .block {
                margin: 35px 10px 0 10px;
                text-align: center;
                background-color: gray;
                color: white;
                border-radius: 0.7em;
                font-size: 12px;

                &.danger {
                    background-color: red;
                }
            }
        }

        &__second {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            border-right: 1px solid #d9d5d5;

            .block {
                background-repeat: no-repeat;
                background-position-x: center;
                background-position-y: 5px;
                background-size: auto 25px;
                padding-top: 35px;
                text-align: center;
                font-size: 12px;
            }
        }

        &__last {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .block {
                background-repeat: no-repeat;
                background-position-x: center;
                background-position-y: 5px;
                background-size: auto 25px;
                padding-top: 35px;
                text-align: center;
                font-size: 12px;
            }
        }
    }

    .productWater {
        /*background-image: url("../../components/market/icons/water_drop.svg");*/
    }
    
    .productBottle {
        /*background-image: url("../../components/market/icons/bottle_dispence.svg");*/
    }
    
    .productCard {
        /*background-image: url("../../components/market/icons/card_dispence.svg");*/
    }
    
    .productCork {
        /*background-image: url("../../components/market/icons/cork_dispence.svg");*/
    }
    
    .moneyCash {
        /*background-image: url("../../components/market/icons/cash_count.svg");*/
    }
    
    .moneyBank {
        /*background-image: url("../../components/market/icons/cashless_count.svg");*/
    }
}

.cardList {
    background-color: #fff;
    border: 1px solid #d9d5d5;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__head {
        text-align: left;
        color: #4a76a8;
        padding: 7px;
        width: 260px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        &__number {
            font-size: 16px;
            font-weight: bold;
            display: inline-block;
            width: 40px;
            text-align: center;
        }

        &__address {
            font-size: 16px;
            white-space: nowrap;
        }
    }

    &__content {
        display: flex;
        height: 30px;

        &__first {
            /*background-image: url('../../components/market/icons/events_count.svg');*/
            background-position-y: center;
            background-position-x: left;
            background-repeat: no-repeat;
            background-size: 17px;

            div {
                text-align: center;
                font-size: 12px;
                width: 70px;
                padding: 7px 0 0 0;
            }
        }

        &__second {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            div {
                background-repeat: no-repeat;
                text-align: center;
                font-size: 12px;
                width: 70px;
                padding: 7px 0 0 0;

                &:nth-child(1) {
                    background-size: 15px;
                    background-position-y: center;
                    background-position-x: left;
                }

                &:nth-child(2) {
                    background-size: 12px;
                    background-position-y: 3px;
                    background-position-x: left;
                }

                &:nth-child(3) {
                    background-size: 17px;
                    background-position-y: 5px;
                    background-position-x: left;
                }
            }
        }

        &__last {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            div {
                background-position-y: center;
                background-position-x: left;
                background-repeat: no-repeat;
                background-size: 15px;
                text-align: center;
                font-size: 12px;
                width: 70px;
                padding: 7px 0 0 0;
            }
        }
    }

    .productWater {
        /*background-image: url("../../components/market/icons/water_drop.svg");*/
    }
    
    .productBottle {
        /*background-image: url("../../components/market/icons/bottle_dispence.svg");*/
    }
    
    .productCard {
        /*background-image: url("../../components/market/icons/card_dispence.svg");*/
    }
    
    .productCork {
        /*background-image: url("../../components/market/icons/cork_dispence.svg");*/
    }
    
    .moneyCash {
        /*background-image: url("../../components/market/icons/cash_count.svg");*/
    }
    
    .moneyBank {
        /*background-image: url("../../components/market/icons/cashless_count.svg");*/
    }
}

.menuContainer {
    background-color: #2b3e50;
    border-radius: 5px;
    height: 38px;
    padding: 5px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        color: white;
        text-decoration: none;
        font-size: 12px;
        padding: 5px;

        &.active {
            background-color: #fff;
            color: #2b3e50 !important;
            border-radius: 3px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .page {
        display: flex;
        flex-direction: row;
    
        &__left {
            // padding-right: 7px;
            // width: 25%;
            width: 100%;
        }
    
        &__right {
            padding-left: 7px;
            width: 75%;
        }
    }

    .cardGrid {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__head {   
            border-bottom: none; 
            padding: 0;

            &__address {
                display: none;
            }
        }
    
        &__content {
            display: flex;
            justify-content: center;

            &__first {
                border-right: none;

                .block {
                    padding: 2px 5px;
                }
            }
    
            &__second {
                display: none;
            }
    
            &__last {
                display: none;
            }
        }
    }
    
    .cardList {
        background-color: #fff;
        border: 1px solid #d9d5d5;
        border-radius: 9px;
        cursor: pointer;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    
        &__head {
            text-align: left;
            color: #4a76a8;
            padding: 7px;
            width: 200px;
    
            &__number {
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
                width: 40px;
                text-align: center;
            }
    
            &__address {
                font-size: 16px;
            }
        }
    
        &__content {
            display: flex;
            height: 30px;
    
            &__first {
                /*background-image: url('../../components/market/icons/events_count.svg');*/
                background-position-y: center;
                background-position-x: left;
                background-repeat: no-repeat;
                background-size: 15px;
    
                div {
                    text-align: center;
                    font-size: 12px;
                    width: 70px;
                    padding: 7px 0 0 0;
                }
            }
    
            &__second {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
    
                div {
                    background-position-y: center;
                    background-position-x: left;
                    background-repeat: no-repeat;
                    background-size: 15px;
                    text-align: center;
                    font-size: 12px;
                    width: 70px;
                    padding: 7px 0 0 0;
                }
            }
    
            &__last {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
    
                div {
                    background-position-y: center;
                    background-position-x: left;
                    background-repeat: no-repeat;
                    background-size: 15px;
                    text-align: center;
                    font-size: 12px;
                    width: 70px;
                    padding: 7px 0 0 0;
                }
            }
        }
    }

    .listWrapper__cardGrid {
        display: flex;
        flex-wrap: wrap;
    }
    
    .listWrapper__cardList {
        display: flex;
        flex-wrap: wrap;
    }

    .gridChanger {
        display: none;
    }

    .tooltip {
        display: none !important;
    }

    .toolbar {
        display: none;
    }

    .menuContainer {
        flex-wrap: wrap;
        height: auto;
    }

    .details {
        &__container {
            &--fixed {
                padding: 5px;
                position: fixed;
                width: 100%;
                background: #fff;
                z-index: 10;
            }

            .commands {
                grid-template-columns: repeat(2, 1fr);
                height: auto;
            }
        }
    }
}