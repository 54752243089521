.text {
    &--blue {
        color: $blue;
    }

    &--nomb {
        margin-bottom: 0;
    }

    &--bold {
        font-weight: bold;
    }

    &--12 {
        font-size: 12px;
    }
}