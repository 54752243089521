.events {
    background-color: white;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 10px 10px 0;

    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__event {
        margin-bottom: 10px;
        position: relative;
        border-bottom: 1px solid;

        .btn {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 1px 3px;
            line-height: 1;
        }

    }

    &__time {
        font-size: 12px;
        display: block;
    }

    &__message {
        font-size: 12px;
    }

    .table {
        &--events {
          text-align: left;

          thead {
            text-align: center;
          }

          td {
            position: relative;
          }

          button {
            position: absolute;
            right: 0;
            width: 20px;
            height: 20px;
            padding: 0;
            font-size: 13px;
            background: $blue;
            outline: none;

            &:hover {
                background: $blue;
            }
          }
        }
    }
}

.eventicon {
    width: 25px;
    height: 25px;
    margin-right: 5px;

    &__debug {
        path {
            stroke: #cecece;
            fill: #cecece;
        }
    }

    &__info {
        path {
            stroke: #363636;
            fill: #363636;
        }
    }

    &__failure {
        path {
            &:last-child {
                stroke: #f05e1b;
                fill: #f05e1b;
            }
        }
    }

    &__warning {
        path {
            stroke: #fdda14;
            fill: #fdda14;
        }
    }

    &__error {
        path {
            stroke: #ff0000;
            fill: #ff0000;
        }
    }

    &__critical {
        path {
            stroke: #831313;
        }
    }
}

@include media-breakpoint-down(sm) {
    .events {    
        &__message {
            font-size: 11px;
        }
    }
}