.header {
    background-color: white;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__left {
        float: left;
        margin-left:5px;
    }

    &__right {
        float: right;
        font-size: 12px;
        padding: 5px;
        line-height: 14px;
    }
}