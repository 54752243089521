input.c_input {
  padding: .375rem .75rem;
  border-radius: .5rem;
  margin-right: 2px;
}
input[type='number'].c_input {
  width:100px;
}

.w_input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: none;
  border-radius: 30px;
  text-align: center;
  color: $gray_text;
  outline: $blue;
  border: 2px solid transparent;

  &::placeholder {
    color: $gray_text;
  }
}

.w_checkbox {
  appearance: none;
  margin: 0 10px 0 0;

  &:after {
    content: '\2714';
    color: #fff;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 16px;
    border: 1px solid $blue;
    border-radius: 20px;
  }

  &:checked {
    &:after {
      content: '\2714';
      font-size: 14px;
      background: $blue;
    }
  }
}

.w_button {
  width: 80%;
  padding: 11px;
  font-size: 16px;
  border-radius: 30px;
  background: $blue;
  color: #fff;
  margin: 0 auto;
  border: none;
}

.button {
  &--blue {
    background: $blue;

    &:hover {
      background: $blue;
    }
  }
}

.w_form {
  &--center {
    text-align: center;
  }
}

.w_table {
  width: 100%;
  text-align: center;
  border: 1px solid $blue;
  border-collapse: collapse;

  th, td {
    border: 1px solid $blue;
    padding: 5px;
  }
}