.disconect {
    width: 100%;
    background-image: url("./assets/disconect.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.offline td {
    text-decoration: line-through
}