@import './elements/timer';
@import './elements/loader';

.detailsTable {
    width: 100%;
    border-collapse: collapse;
    
    caption, p {
        color: $blue;
        margin-bottom: 10px;
    }

    &__head {
        td {
            padding-bottom: 10px;
            font-weight: bold;
        }
    }

    td {
        padding: 5px 0;
    }
}

hr {
    margin: 15px 0;
}

.table {
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;

    &--border {
        border: 1px solid black;
        border-collapse: collapse;
        border-radius: 5px;
        border-style: hidden;
        box-shadow: 0 0 0 1px black;
        overflow: hidden;

        td {
            border: 1px solid black;
            border-collapse: collapse;
        }
    }

    &--hover {
        tr {
            cursor: pointer;

            td {
                padding: 3px;
            }
    
            &:hover {
                background: #eee;
            }
        }
    }

    &--main {
        tr {
            td {
                padding: 5px;
            }
        }
    }

    tr {
        &.active {
            background: rgb(120, 255, 86);
        }
    }
}

.nav-link {
    color: $blue;
}

.nav-pills .nav-link {
    color: $blue;

    &.active {
        background-color: $blue;
    }
}


.nav-tabs .nav-link.active {
    background-color: $blue;
    color: #fff;
}