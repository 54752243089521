.ldsDualRing {
    margin: 0px auto;
    display: block;
    position: relative;
    top: 13px;

    &:after {
        content: " ";
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 6px solid $blue;
        border-color: $blue transparent $blue transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
