.loginFormWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.loginFormErrors {
    color: red;
}

.loginFormWrapper:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.loginFormWrapper form {
    width: 300px;
}