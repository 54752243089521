.managment {
    &__nav {

    }
}

@include media-breakpoint-down(sm) {
    .managment {
        &__nav {
            flex-wrap: nowrap;
            overflow-x: scroll;
        }
    }
}