.w_row {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &--no-mb {
    margin-bottom: 0;
  }
}

.mb {
  margin-bottom: 15px;

  &-50 {
    margin-bottom: 50px;
  }
}

.w_container {
  width: 100%;
  padding: 0 15px;
  max-width: 400px;
  margin: 0 auto;
}
.commands_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 60px;
}
.commands_container div {
  padding: 3px;
}
.commands_container div div {
  border: 1px solid #999;
  border-radius: .5em;
  height: 100%;
  text-align: center;
}

.commands_container div div:hover {
  cursor: pointer;
  background-color: #CCC;
}