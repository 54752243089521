@include media-breakpoint-down(sm) {
    .navbar-collapse {
        position: absolute;
        z-index: 20;
        width: 100%;
        left: 0;
        top: 48px;
        background: #fff;
        padding: 10px;
    }
}