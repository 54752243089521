.timer {
    margin-left: 15px;

    &__toggle {
        &:before {
            color: $blue;
        }

        svg {
            fill: $blue;
        }
    }

    &__menu {
        padding: 0;
    }

    &__item {
        position: relative;

        &.active {
            background: #e7e7e7;

            svg {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &:active {
            background-color: #fff;
        }
    }
}